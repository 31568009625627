// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/runner/work/frontendu.github.io/frontendu.github.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-html-js": () => import("/home/runner/work/frontendu.github.io/frontendu.github.io/src/pages/donate.html.js" /* webpackChunkName: "component---src-pages-donate-html-js" */),
  "component---src-pages-donate-js": () => import("/home/runner/work/frontendu.github.io/frontendu.github.io/src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/frontendu.github.io/frontendu.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/runner/work/frontendu.github.io/frontendu.github.io/.cache/data.json")

